.logout a {
    margin-top: 20px;
    display: block;
    color: #666;
    text-decoration: none;
}

/* Main Dashboard Content */
.dashboard-content {
    margin-left: 270px;
    padding: 20px;
}

.welcome {
    color: #333;
}

.avatar {
    border-radius: 50%;
}

.status-section {
    margin-top: 40px;
}

.status-grid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 20px;
    margin-top: 20px;
}

.status-card {
    background-color: white;
    padding: 20px;
    border-radius: 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
}

.dashboard-ticket-content {
    text-align: left;
    margin-top: 20px;
    align-self: flex-start;
}

.dashboard-ticket-content p {
    /*line-height: 10px;*/
}

.ticket-status {
    width: 120px;
    height: 30px;
    align-content: center;
    border-radius: 100px;
    padding: 8px 16px 8px 16px;
    font-weight: bold;
}

.ticket-priority {
    display: flex;
    justify-content: space-between;
    gap: 5px;
    align-items: center;
}

.ticket-title {
    display: flex;
    justify-content: space-between;
}

.priority {
    border-width: 1px;
    border-style: solid;
    border-radius: 4px;
    padding: 0 8px 0 8px;
    font-weight: bold;
}

.priority p {
    line-height: 1px;
}

.priority-normal {
    background-color: #E5F9FF;
    border-color: #00A1EB;
    color: #00A1EB;
}

.priority-low {
    background-color: #E5FFDE;
    border-color: #00CF3A;
    color: #00CF3A
}

.priority-high {
    background-color: #FFF5DE;
    border-color: #EE9D00;
    color: #EE9D00;
}

.priority-urgent {
    background-color: #FFDEDE;
    border-color: #FF0000;
    color: #FF0000;
}

.status-open {
    background-color: #E5F9FF;
    color: #00A1EB
}

.status-solved {
    background-color: #F1FFE5;
    color: #2AD000
}

.status-wip {
    background-color: #FFF5E5;
    color: #EBA800
}

.deal-grid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 20px;
    margin-top: 20px;
}

.deal-card {
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: 20px;
    border-radius: 20px;
    text-align: left;
}

.deal-card p {
    margin: 5px 0;
}

.ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 150px;
}