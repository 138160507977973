.new-ticket-content {
    margin-left: 270px;
    padding: 20px;
}

.new-ticket-container-form {
    display: grid;
    gap: 20px;
}

.new-ticket-container-form input{
    width: 50%;
    border-width: 0 0 2px 0;
    background-color: transparent;
    border-color: #E7E7E7;
}

.new-ticket-container-form input:focus{
    outline: none;
}

.form-section {
    margin-bottom: 20px;
}

.form-section input {
    padding: 10px;
    margin: 5px 0;
}

.form-section select {
    padding: 10px;
    margin: 5px 0;
    border-radius: 5px;
    border: 1px solid #ccc;
}