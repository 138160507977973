/*form {*/
/*    padding-top: 80px;*/
/*    max-width: 340px;*/
/*    margin: 0 auto;*/
/*    display: grid;*/
/*    grid-template-columns: 1fr;*/
/*    gap: 16px;*/
/*}*/

/* Input style */
.input-container {
    position: relative;
    display: flex;
    flex-direction: column;
}

.input-container:focus-within label {
    transform: translate(0, 12px) scale(0.8);
    color: #8E8E8E;
}

.input-container .filled {
    transform: translate(0, 12px) scale(0.8);
}

.input-container label {
    position: absolute;
    pointer-events: none;
    transform: translate(0, 23px) scale(1);
    transform-origin: top left;
    transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    color: #8E8E8E;
    font-size: 16px;
    line-height: 1;
    left: 16px;
}

.input-container input {
    height: 30px;
    border-width: 0 0 1px 0;
    padding: 24px 16px 4px 16px;
    font-size: 16px;
    line-height: 1;
    outline: none;
    box-shadow: none;
    transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

/*.input-container input:focus {*/
/*    box-shadow: 0 0 0 2px #79b1ff;*/
/*}*/