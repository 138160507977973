.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease;
}

.modal-overlay.open {
    z-index: 100;
    opacity: 1;
    visibility: visible;
    color: #333333;
}

/* Modal Content */
.modal-content {
    width: 40%;
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.3s ease, transform 0.3s ease;
    height: fit-content;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap:20px;
    justify-content: space-between;
}

/* When the modal is visible */
.modal-content.show {
    opacity: 1;
    transform: translateY(0);
}

.modal-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.modal-form textarea {
    border-radius: 5px;
}

.btn-submit-course {
    cursor: pointer;
    border-radius: 30px;
    padding: 13px 26px;
    border: none;
    background-color: #FD4809;
    color: white;
    font-weight: 600;
    font-family: Inter, sans-serif;
    width: 30%;
    align-self: end;
}