.deal-registration-content {
    margin-left: 270px;
    padding:20px;
    text-align: center;
}

.deal-registration-content-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.btn-load-more {
    cursor: pointer;
    border-radius: 30px;
    padding: 13px 26px;
    border: none;
    background-color: #FD4809;
    color: white;
    font-weight: 600;
    font-family: Inter, sans-serif;
    width: 20%;
    margin-top: 20px;
}