@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.app {
  display: flex;
  font-family: Inter, sans-serif;
}

h1 {
  color: #383C3E;
}

/* Sidebar */
.sidebar {
  width: 250px;
  background-color: white;
  padding: 20px;
  position: fixed;
  min-height: 100vh;
  left: 0;
  font-family: Poppins, sans-serif;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.sidebar .submenu {
  margin-left: 20px; /* Indent child items */
  display: flex;
  flex-direction: column;
}

.submenu-item {
  font-size: 14px;
  color: #555;
  padding: 5px 0;
  text-decoration: none;
}

.submenu-item.active {
  color: #ff6600; /* Highlight active child items */
}

.menu-item.active + .submenu {
  display: block; /* Ensure submenu is shown when parent is active */
}


.logo h1 {
  font-size: 24px;
  margin-bottom: 0;
}

.logo p {
  font-size: 12px;
}

.sidebar-menu {
  margin-top: 40px;
}

.sidebar-menu a {
  display: block;
  padding: 10px;
  color: #6D7C90;
  text-decoration: none;
  margin-bottom: 10px;
}

.sidebar-menu a.active {
  background-color: #ff4500;
  color: white;
  border-radius: 100px;
  padding: 12px 20px 12px 20px;
}

.main-content {
  background-color: #f4f4f4;
  flex-grow: 1;
  padding: 20px;
  min-height: 100vh;
  margin-left: 30px;
}

h1 {
  font-size: 44px;
}

h2 {
  font-size: 35px;
}

h3 {
  font-size: 30px;
}
