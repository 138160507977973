.progress-bar-container {
    position: fixed; /* Fixes the position relative to the viewport */
    bottom: 20px; /* Distance from the bottom */
    right: 20px; /* Distance from the right */
    width: 200px; /* Width of the progress bar container */
    background-color: #e0e0e0; /* Background color of the container */
    border-radius: 5px; /* Rounded corners */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Optional shadow for depth */
    z-index: 1000; /* Ensure it appears above other elements */
}

.progress-bar {
    height: 20px; /* Height of the progress bar */
    background-color: #76c7c0; /* Color of the progress bar */
    border-radius: 5px; /* Rounded corners */
    transition: width 0.3s ease; /* Smooth transition for width changes */
    position: relative; /* Position relative for text */
}

.progress-text {
    position: absolute; /* Position text absolutely within the progress bar */
    left: 50%; /* Center horizontally */
    top: 50%; /* Center vertically */
    transform: translate(-50%, -50%); /* Adjust for centering */
    color: white; /* Text color */
    font-weight: bold; /* Bold text */
}