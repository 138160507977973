.class-content-page {
    display: flex;
    margin-left: 270px;
    padding: 20px;
    font-family: Inter, sans-serif;
}

.container-left {
    flex: 1;
}

.container-right {
    flex: 1;
}
.course-content {
    background-color: white;
    border-radius: 10px;
    padding: 20px;
}

.course-content h2 {
    margin-bottom: 20px;
}

.class-description {
    margin-top: 20px;
}

/*.lesson-item {*/
/*    display: flex;*/
/*    justify-content: space-between;*/
/*    padding: 10px 0;*/
/*    background-color: white;*/
/*}*/

.lesson-item span:last-child {
    color: rgba(255, 255, 255, 0.4);
    font-size: 12px;
}

.course-content ul {
    list-style-type: none;
    padding: 0;
}

.lesson-item {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    padding: 10px 15px;
    margin-bottom: 10px;
    border-radius: 14px;
    cursor: pointer;
    transition: background-color 0.3s;
    height: 70px;
    background-color: white;
}

.lesson-item:hover {
    background-color: #f0f0f0;
}

.lesson-item.active {
    background-color: #ff5722; /* Highlight color for active lesson */
    color: white;
}

.lesson-item-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: auto;
}