.profile-page {
    margin-left: 280px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.profile-content {
    display: flex;
    flex-direction: row;
    gap:20px;
}

.account-settings {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    padding: 20px;
    background: #fff;
    border-radius: 40px;
}

.account-settings-form {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    gap: 0;
}

.account-settings-form input {
    padding: 18px 26px 18px 26px;
    margin: 10px 0;
    border: 1px solid #ddd;
    border-radius: 16px;
}

.password-settings {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    padding: 20px;
    background: #fff;
    border-radius: 40px;
}

.profile-picture-container {
    display: flex;
    background-color: white;
    border-radius: 40px;
    padding: 20px;
}

.submit-btn {
    padding: 18px 26px 18px 26px;
    background-color: #FD4809;;
    color: white;
    border: none;
    border-radius: 30px;
    cursor: pointer;
    margin-top: 20px;
    transition: background-color 0.3s ease;
}
