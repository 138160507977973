.new-deal-registration-content {
    margin-left: 270px;
    padding:20px;
}

.new-deal-registration-content-header {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.new-deal-registration-container-form {
    display: grid;
    grid-template-columns:repeat(2, 1fr);
    gap: 20px;
}

.new-deal-registration-container-form input{
    width: 50%;
    border-width: 0 0 2px 0;
    background-color: transparent;
    border-color: #E7E7E7;
}

.new-deal-registration-container-form input:focus{
    outline: none;
}

.new-deal-registration-btn {
    background-color: #FD4809;
    border-radius: 100px;
    padding: 20px 30px;
    border: none;
    cursor: pointer;
    font-weight: 600;
    color: white;
}

.grid-item {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.form-section input {
    padding: 10px;
    margin: 5px 0;
}

.form-section select {
    padding: 10px;
    margin: 5px 0;
    border-radius: 5px;
    border: 1px solid #ccc;
}