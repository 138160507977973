.upload-content-container {
    margin-left: 270px;
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: 20px;
    border-radius: 5px;
}

.upload-content-form {
    display: flex;
    flex-direction: column;
    gap:10px;
}

.upload-content-form img {
    width: 30%;
    height: 200px;
    object-fit: cover;
    object-position: center;
}

.loop-course-content {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 16px;
}

.btn-submit-class {
    cursor: pointer;
    border-radius: 30px;
    padding: 13px 26px;
    border: none;
    background-color: #FD4809;
    color: white;
    font-weight: 600;
    font-family: Inter, sans-serif;
    width: 20%;
    align-self: end;
}

.course-content-upload {
    position: relative;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    background-color: white;
    padding-bottom: 20px;
    border-radius: 20px;
    align-items: center;
    gap: 15px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    /*width: 350px;*/
    /*height: 200px;*/
}

.course-content-upload img {
    object-fit: cover;
    border-radius: 15px 15px 0 0;
    width: 100%;
    height: 200px;
}

.delete-icon {
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
    color: red;
    font-size: 18px;
    font-weight: bold;
    z-index: 1;
    background-color: rgba(255, 255, 255, 0.69);
    border-radius: 50%;
    width: 24px; /* Width of the circle */
    height: 24px;
    display: flex; /* Flexbox to center the X */
    justify-content: center; /* Center the X horizontally */
    align-items: center;
}

/*.input-field {*/
/*    padding: 10px;*/
/*    font-size: 16px;*/
/*    border: 1px solid #ccc;*/
/*    border-radius: 5px;*/
/*}*/