.upload-content-page {
    margin-left: 270px;
    padding: 20px;
}

.upload-content {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 16px;
}

.add-content-box {
    border: 2px dashed #ccc;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /*width: 350px;*/
    /*height: 200px;*/
    border-radius: 20px;
    cursor: pointer;
}

.class-box {
    padding-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /*width: 350px;*/
    /*height: 200px;*/
    border-radius: 20px;
    cursor: pointer;
    background-color: white;
    position: relative;
    gap: 15px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}

.class-box img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 15px 15px 0 0;
}
