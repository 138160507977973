/* Layout */
.auth-page {
    display: flex;
    height: 100vh;
}

.auth-left {
    flex: 1;
    background: linear-gradient(180deg, #FF914D, #923800); /* Gradient */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    padding: 20px;
}

.auth-left h1 {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
}

.auth-left p {
    font-size: 16px;
    text-align: center;
    margin-top: 20px;
}

/* Right section */
.auth-right {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.back-to-home {
    position: absolute;
    top: 20px;
    left: 20px;
    text-decoration: none;
    color: #333;
}

.auth-form-container {
    width: 100%;
    max-width: 400px;
    text-align: center;
}

.toggle-buttons {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.toggle-buttons button {
    flex: 1;
    padding: 10px 0;
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 18px;
    color: #999;
    outline: none;
    border-bottom: 2px solid transparent;
}

.toggle-buttons .active {
    color: #333;
    border-bottom: 2px solid #333;
}

.auth-form {
    display: flex;
    flex-direction: column;
}

.auth-form input {
    padding: 18px 26px 18px 26px;
    margin: 10px 0;
    border: 1px solid #ddd;
    border-radius: 16px;
}

.submit-btn {
    padding: 18px 26px 18px 26px;
    background-color: #B0B0B0;;
    color: white;
    border: none;
    border-radius: 30px;
    cursor: pointer;
    margin-top: 20px;
    transition: background-color 0.3s ease;
}

.submit-btn.active {
    background-color: #FD4809; /* Green when active (form filled) */
    cursor: pointer; /* Enable the cursor when the form is valid */
}

.forgot-password {
    margin-top: 10px;
    display: block;
    color: #999;
    text-decoration: none;
}

.forgot-password:hover {
    color: #333;
}
