.modal-content-delete {
    width: 20%;
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.3s ease, transform 0.3s ease;
    height: fit-content;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap:20px;
    justify-content: space-between;
}

.modal-content-delete.show {
    opacity: 1;
    transform: translateY(0);
}

.container-button {
    display: flex;
    gap: 10px;
    justify-content: end;
}

.container-button button {
    cursor: pointer;
    border-radius: 30px;
    padding: 13px 26px;
    border: none;
    background-color: rgb(212, 209, 209);
    color: white;
    font-weight: 600;
    font-family: Inter, sans-serif;
    width: 20%;
}

.container-button button:last-child {
    cursor: pointer;
    border-radius: 30px;
    padding: 13px 26px;
    border: none;
    background-color: #FD4809;
    color: white;
    font-weight: 600;
    font-family: Inter, sans-serif;
    width: 20%;
}