.ticket-content {
    margin-left: 270px;
    padding: 20px;
}

.priority-chip {
    width: fit-content;
    height: 24px;
    border-style: solid;
    border-width: 0.5px;
    padding: 0 8px;
    border-radius: 4px;
    align-content: center;
}

.Normal {
    background-color: #E5F9FF;
    border-color: #00A1EB;
    color: #00B4EB;
}

.High {
    background-color: #FFF5E5;
    border-color: #EBA800;
    color: #EBB000;
}

.Low {
    background-color: #F1F1F1;
    border-color: #9A9A9A;
    color: #848484;
}

.Urgent {
    background-color: #FFE5E7;
    border-color: #DA1E28;
    color: #DA1E28;
}

.Cancelled {
    background-color: #FFE5E7;
    border-color: #DA1E28;
    color: #DA1E28;
}

.status-chip, .priority-chip {
    width: fit-content;
    height: 24px;
    border-style: solid;
    border-width: 0.5px;
    padding: 0 8px;
    border-radius: 4px;
    align-content: center;
}

.status-open {
    background-color: #E5F9FF;
    color: #00A1EB
}

.status-solved {
    background-color: #F1FFE5;
    color: #2AD000
}

.status-wip {
    background-color: #FFF5E5;
    color: #EBA800
}

.priority-normal {
    background-color: #E5F9FF;
    border-color: #00A1EB;
    color: #00A1EB;
}

.priority-low {
    background-color: #E5FFDE;
    border-color: #00CF3A;
    color: #00CF3A
}

.priority-high {
    background-color: #FFF5DE;
    border-color: #EE9D00;
    color: #EE9D00;
}

.priority-urgent {
    background-color: #FFDEDE;
    border-color: #FF0000;
    color: #FF0000;
}

.entitlement-chip {
    width: fit-content;
    height: 24px;
    border-style: solid;
    border-width: 0.5px;
    padding: 0 8px;
    border-radius: 4px;
    align-content: center;
}

.SLA {
    background-color: #F1FFE5;
    border-color: #2AD000;
    color: #2AD000;
}

.pagination-controls {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.next-prev {
    color: #EB6200;
    outline: unset;
    border: none;
    cursor: pointer;
    background-color: transparent;
}

.next-prev:disabled {
    color: #9A9A9A;
    cursor: unset;
}

.table-cell-ellipsis {
    white-space: nowrap; /* Prevent text from wrapping */
    overflow: hidden; /* Hide overflow text */
    text-overflow: ellipsis; /* Add ellipsis for overflow text */
    max-width: 100px; /* Set a maximum width for the cell */
}