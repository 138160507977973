.university-content-page {
    margin-left: 270px;
    padding: 20px;
    font-family: Inter, sans-serif;
}

.university-content{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 16px;
}

.university-class{
    cursor: pointer;
    display: flex;
    flex-direction: column;
    background-color: white;
    padding-bottom: 20px;
    border-radius: 20px;
    gap: 15px;
    align-items: center;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}

.university-class img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 14px 14px 0 0;
}

.span-title {
    color: #B0B0B0;
    font-weight: 500;
    font-size: 1em;
}

.span-description {
    color: #1E1B1A;
    font-weight: 500;
    font-size: 1.2em;
}